// in ./CustomResetViewsButton.tsx
import { useState } from "react";
import {
  Button,
  Confirm,
  useListContext,
  useUpdateMany,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin";

import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
export const CustomSetPaperTradeOnButton = () => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("weeklystockwatches");
  const [updateMany] = useUpdateMany(
    "weeklystockwatchesupdatepapertradestatus",
    { ids: selectedIds, data: { PaperTradeOnly: true } },
    {
      onSuccess: () => {
        refresh();
        notify("Paper Trade Set ON", { autoHideDuration: 5000 });
        unselectAll();
      },
      onError: (error) => {
        notify("Error: stock watches not updated", { type: "error" });
        refresh();
      },
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<ThumbUpOffAltIcon color="primary" />}
        label="Turn Paper Trade ON"
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        title="Turn Paper Trade ON"
        content="Are you sure you want to turn Paper Trade ON for these items??"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export const CustomSetPaperTradeOffButton = () => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("weeklystockwatches");
  const [updateMany] = useUpdateMany(
    "weeklystockwatchesupdatepapertradestatus",
    { ids: selectedIds, data: { PaperTradeOnly: false } },
    {
      onSuccess: () => {
        refresh();
        notify("Paper Trade Set OFF", { autoHideDuration: 5000 });
        unselectAll();
      },
      onError: (error) => {
        notify("Error: stock watches not updated", { type: "error" });
        refresh();
      },
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<ThumbDownOffAltIcon color="primary" />}
        label="Turn Paper Trade OFF"
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        title="Turn Paper Trade OFF"
        content="Are you sure you want to turn Paper Trade OFF for these items??"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
