import AutorenewIcon from "@mui/icons-material/Autorenew";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorIcon from "@mui/icons-material/Error";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import { IconButton, Tooltip } from "@mui/material";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import {
  CreateDialogComponent,
  EditDialogComponent,
} from "components/forms/dialogs/ModalDialog";
import {
  BasicNumberField,
  EmptyDataForm,
  ListActions,
  MyCurrentPriceField,
  MyDatagridConfigurable,
  MyJsonToString,
  UseStyles,
} from "components/getForms/FormUtilities";
import { useCallback } from "react";
import {
  BooleanField,
  EditButton,
  Pagination,
  List as RAList,
  TextField,
  WrapperField,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import {
  StockTransactionEditStalledForm,
  StockTransactionsEditableGrid,
  WeeklyStockWatchForm,
} from "../../getForms/GetForms";
import { WeeklyStockWatchFilters } from "../../getForms/ListFilters";

import { CustomSetPaperTradeOnButton } from "../../buttons/UpdatePaperTradeOnlyButton";
import { CustomSetPaperTradeOffButton } from "../../buttons/UpdatePaperTradeOnlyButton";
import { BulkDeleteButton } from "react-admin";

const PostBulkActionButtons = (props: any) => (
  <>
    <BulkDeleteButton {...props} />
    <CustomSetPaperTradeOnButton />
    <CustomSetPaperTradeOffButton />
    {/* default bulk delete action */}
  </>
);

export const WeeklyStockWatchList = (props: any) => {
  const refresh = useRefresh();
  const record = useRecordContext(props);
  const notify = useNotify();

  const [update, { data, isLoading, error }] = useUpdate();

  const classes = UseStyles();

  function ClearWeeklyStockWatch(event: any, record: any) {
    //https://marmelab.com/react-admin/doc/2.9/Actions.html#using-a-custom-action-creator
    //https://marmelab.com/react-admin/Actions.html#calling-custom-methods
    const weeklyStockWatchId = record.id;

    update(
      "weeklystockwatchesclearposition",
      { id: weeklyStockWatchId },
      {
        onSettled(data, error: any, variables, context) {
          //for now... NOT using onSuccess and OnError
          //but rather onSettled... as can't get the other 2 to work
          //under this use case.
          //Just checking on whether the error.body has been set or not.
          if (error.body != undefined) {
            notify(error.body.message, {
              type: "warning",
            });
          } else {
            refresh();
            notify("Position Cleared.", {
              type: "success",
              autoHideDuration: 5000,
            });
          }
        },
      }
    );

    return null;
  }

  function CloseLivePositionWeeklyStockWatch(event: any, record: any) {
    //https://marmelab.com/react-admin/doc/2.9/Actions.html#using-a-custom-action-creator
    //https://marmelab.com/react-admin/Actions.html#calling-custom-methods
    const weeklyStockWatchId = record.id;

    update(
      "weeklystockwatchescloseposition",
      { id: weeklyStockWatchId },
      {
        onSettled(data, error: any, variables, context) {
          //for now... NOT using onSuccess and OnError
          //but rather onSettled... as can't get the other 2 to work
          //under this use case.
          //Just checking on whether the error.body has been set or not.
          if (error.body != undefined) {
            notify(error.body.message, {
              type: "warning",
            });
          } else {
            notify("Position Closed.", {
              type: "success",
              autoHideDuration: 5000,
            });
          }
        },
      }
    );

    return null;
  }

  const closeDialogWithRefresh = useCallback(() => {
    refresh();
  }, []);

  const StockWatchActions = (props: any) => {
    const record = useRecordContext(props);
    const formContext = useFormContext();

    var currentPrice;
    //const { getValues } = useFormContext();
    let stockWatchId = 0;
    let hasCurrentPrice = true;
    if (record !== undefined) {
      stockWatchId = Number(record.id);
      currentPrice = record.CurrentPrice;
      if (currentPrice === undefined) {
        hasCurrentPrice = false;
      } else if (currentPrice == 0) {
        hasCurrentPrice = false;
      }
    }
    return (
      <WrapperField label="Actions" source="actions">
        <Tooltip title="Edit" placement="bottom" aria-label="Edit">
          <IconButton
            edge="start"
            size="small"
            sx={{
              marginRight: 0,
              minWidth: 0,
            }}
          >
            <EditButton
              size="small"
              label=""
              scrollToTop={false}
              fullWidth={false}
              className={classes.minWidthAuto}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Clear Position" aria-label="Clear Position">
          <IconButton
            size="small"
            sx={{
              marginRight: 0,
              minWidth: "auto",
            }}
            onClick={(e: any) => {
              if (
                window.confirm("Are you sure you wish to clear this position?")
              )
                //  e.preventDefault(); // necessary to prevent default SaveButton submit logic
                //const { id, ...data } = getValues();
                //   const weeklyStockWatchId = record.id;

                ClearWeeklyStockWatch(e, record);
            }}
          >
            <AutorenewIcon color="primary" />
          </IconButton>
        </Tooltip>

        {hasCurrentPrice && (
          <Tooltip title="Close Live Position" aria-label="Close Live Position">
            <IconButton
              size="small"
              sx={{
                marginRight: 0,
                minWidth: "auto",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (
                  window.confirm(
                    "Are you sure you wish to close this Live position?"
                  )
                )
                  CloseLivePositionWeeklyStockWatch(e, record);
              }}
            >
              <ClearIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="History" placement="bottom" aria-label="History">
          <EditInDialogButton
            close={closeDialogWithRefresh}
            className={"no-dialog-styling"}
            inline={true}
            icon={<HistoryToggleOffIcon color="action" />}
            fullWidth
            maxWidth={false}
            title={"History for " + record.Symbol}
            color="primary"
            ButtonProps={{
              variant: "outlined",
              size: "large",
              sx: { margin: "0 auto" },
            }}
            sx={{ margin: "0 auto" }}
            label="History"
          >
            <StockTransactionsEditableGrid
              stockWatchId={stockWatchId}
              inventoryItemCount={record.count}
            />
          </EditInDialogButton>
        </Tooltip>
      </WrapperField>
    );
  };

  const StalledActions = (props: any) => {
    const record = useRecordContext(props);
    const formContext = useFormContext();

    const { data, isLoading, error } = useGetOne(
      "lastweeklystocktransactions",
      {
        id: record.id,
      }
    );
    if (isLoading) return <span></span>;
    if (!record.HasStoppedTrading) {
      return <span></span>;
    }

    let stockTransactionID: any;

    stockTransactionID = data.id;
    return (
      <WrapperField label="Stalled" source="HasStoppedTrading">
        <Tooltip
          title="Edit Stalled Position"
          placement="bottom"
          aria-label="Edit Stalled Position"
        >
          <EditInDialogButton
            close={closeDialogWithRefresh}
            className={"no-dialog-styling"}
            inline={true}
            icon={<ErrorIcon color="error" />}
            fullWidth
            maxWidth="sm"
            title={"Edit Stalled Position: " + record.Symbol}
            color="error"
            ButtonProps={{
              variant: "outlined",
              size: "large",
              sx: { margin: "0 auto" },
            }}
            sx={{ margin: "0 auto" }}
            label="Edit Stalled Position"
            resource="stocktransactions"
            id={stockTransactionID}
          >
            <StockTransactionEditStalledForm />
          </EditInDialogButton>
        </Tooltip>
      </WrapperField>
    );
  };

  const postRowSx = (record: any, index: BigInteger) => ({
    backgroundColor: record.CurrentPrice > 0 ? "#efe" : "white",
  });

  const page = 1;
  const perPage = 5;

  return (
    <div>
      <RAList
        sx={{
          "& .RaList-actions": {
            top: "0px",
            position: "sticky",
            zIndex: "2",
            backgroundColor: "rgb(255, 255, 255)",
          },
          "& .RaBulkActionsToolbar-toolbar": {
            left: "245px!important" as any,
            position: "fixed!important" as any,
          },
        }}
        queryOptions={{ refetchInterval: 5000 }}
        empty={<EmptyDataForm title="Weekly Stock Watch" />}
        resource="weeklystockwatches"
        title="Weekly Stock Watches"
        actions={
          <ListActions
            createButtonLabel="New Stock Watch"
            showFilter={false}
            showCreate={true}
            showExport={true}
          />
        }
        filter={{
          LocalUserID: PersonId,
        }}
        filters={WeeklyStockWatchFilters}
        sort={{ field: "Symbol", order: "ASC" }}
        perPage={100}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
      >
        <MyDatagridConfigurable
          omit={["disabled"]}
          bulkActionButtons={<PostBulkActionButtons />}
          rowSx={postRowSx}
          sx={{
            "& .RaDatagrid-headerCell": { top: "60px" },
            "& .column-actions": { minWidth: "200px" },
            "& .column-ProcessingQuote": { textAlign: "center" },
            "& .column-PaperTradeOnly": { textAlign: "center" },
          }}
        >
          <TextField source="Symbol" />
          <BooleanField
            source="PaperTradeOnly"
            label="Paper Trade"
            FalseIcon={null}
          />
          <StalledActions
            label="Stalled"
            sortable={true}
            source="HasStoppedTrading"
          />
          <MyCurrentPriceField
            source="CurrentPrice"
            decimalPlaces={3}
            label="Current Price"
            sortable={true}
          />
          <MyCurrentPriceField
            source="StrikePrice"
            decimalPlaces={3}
            label="Strike Price"
            sortable={true}
          />
          <BasicNumberField source="BaseQuantity" label="Base Quantity" />

          <MyJsonToString source="CurrentLongPosition" label="Long Position" />
          <MyJsonToString
            source="CurrentShortPosition"
            label="Short Position"
          />
          <BooleanField
            source="UseTrendFollowMode"
            label="Trend Follow"
            FalseIcon={null}
          />
          <StockWatchActions
            source="actions"
            label="Actions"
            sortable={false}
          />
        </MyDatagridConfigurable>
      </RAList>

      <CreateDialogComponent maxWidth={false} form={WeeklyStockWatchForm} />
      <EditDialogComponent maxWidth={false} form={WeeklyStockWatchForm} />
    </div>
  );
};
